.partnersWrapper {
  @apply container mb-0;
}

.marquee {
  --gap: 1rem;
  display: flex;
  overflow: hidden;
  user-select: none;
  gap: var(--gap);

  &:hover .marquee__content {
    animation-play-state: paused;
  }

  &:nth-of-type(2) .marquee__content {
    animation-duration: 20s;
  }

  &:nth-of-type(3) .marquee__content {
    animation-duration: 15s;
  }

  &:nth-of-type(4) .marquee__content { 
    animation-duration: 25s;
  }

  &.inverted .marquee__content {
    img {
      filter: invert(1) brightness(100);
    }
  }

  .marquee__content {
    flex-shrink: 0;
    display: flex;
    justify-content: space-around;
    min-width: 100%;

    gap: var(--gap);
    animation-name: scroll;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-duration: 60s; // default duration
  }

  @media (prefers-reduced-motion: reduce) {
    animation-duration: 180s; // Much slower animation for reduced motion preference

    &:nth-of-type(2) .marquee__content {
      animation-duration: 60s;
    }
  
    &:nth-of-type(3) .marquee__content {
      animation-duration: 45s;
    }
  
    &:nth-of-type(4) .marquee__content { 
      animation-duration: 75s;
    }
  
  }

  .marquee__content li::marker {
    content: "";
  }

  .marquee__content .imageWrapper {
    @apply block my-auto w-56 h-auto;

    .image {
      @apply block max-w-full max-h-full object-contain;
    }
  }
}

@keyframes scroll {
  from {
    transform: translateX(calc(-100% - var(--gap)));
  }
  to {
    transform: translateX(0);
  }
}

@media (prefers-reduced-motion: reduce) {
  .marquee {
    &:hover .marquee__content {
      animation-play-state: running; // Don't pause on hover when reduced motion is active
    }
  }
}
